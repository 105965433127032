import React from 'react'
import './AboutUs.css'
import Navbar from '../../Components/JSX/Navbar'
import Footer from '../../Components/JSX/Footer'
import About from '../../Assets/Images/About.png'

const AboutUs = () => {
  return (
    <>
    
   <div className='about'>
    <Navbar/>
    <img src={About} alt="about" className='aboutimg' />

    <figure class="text-center text">
      <h4>Welcome to CART SHOP, your destination for a seamless and innovative shopping experience. We are passionate about bringing you the best products and making your shopping journey not just convenient but enjoyable. </h4>
      <br/><br/>
  <blockquote class="blockquote">
    <p><h4>Our Vision</h4>

At CART SHOP, we aim to redefine the way you shop online. We believe that technology can empower your shopping experience, making it more efficient and delightful. Our vision is to provide you with a modern and secure platform where you can discover, purchase, and pay for your favorite products, all through the simplicity of a QR code.
<br/><br/><br/>
 <h4>What Sets Us Apart</h4>
 <ul className='aboutul'>
  <li>
  Innovation: We're at the forefront of e-commerce innovation, constantly exploring new ways to improve your shopping experience. Our use of QR codes for payments is just one example of our commitment to innovation.
  </li>

  <li>
  Quality: We handpick each product to ensure that you receive only the best. Quality is at the heart of everything we do.
Security: We prioritize the security of your data and transactions. Our robust security measures ensure your peace of mind when shopping with us.
  </li>

  <li>
  Simplicity: We've designed our platform to be user-friendly, making it easy for anyone to shop with confidence. With a few clicks, you can be on your way to enjoying your purchases.
  </li>
 </ul>

<br/>
<h4> Our Team</h4>

We are a passionate team of individuals who are dedicated to bringing you a superior shopping experience. Our team members come from diverse backgrounds, and we blend our unique expertise to create a platform that reflects the values and expectations of our customers.</p>
  </blockquote>
  
</figure>
    <Footer/>
    </div>    
    </>
  )
}

export default AboutUs

import React from "react";
import "../CSS/Footer.css";
import Logo from "../../Assets/Images/Logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="Footersection">
        <div className="footer-container">
          <div className="left-div">
            <p id="footerheading">Want to stay Connected?</p>
          </div>
          <div className="right-div">
            <Link to="/Contact" className="nav-link">
              <button
                type="button"
                class="btn btn-primary footerfirst  d-flex justify-content-center align-items-center"
              >
                <i
                  class="bi bi-telephone-fill"
                  style={{ fontSize: "22px", paddingRight: "5px" }}
                ></i>
                <span class="align-middle">Contact Us</span>
              </button>
            </Link>

            <Link to="/About" className="nav-link">
              <button
                type="button"
                class="btn btn-secondary footersecond d-flex justify-content-center align-items-center"
              >
                <i
                  class="bi bi-file-person-fill"
                  style={{ fontSize: "22px", paddingRight: "5px" }}
                ></i>
                <span class="align-middle">About Us</span>
              </button>
            </Link>
          </div>
        </div>

        {/* ----------------------------------------------------- */}
        <footer className="footer">
          <div className="footer-left">
            <img src={Logo} alt="Logo" className="footer-logo" />
            <h3 className="brandname">CARTSHOP</h3>
            <br />
            <br />
            <p className="footer-text">
              Welcome to CART SHOP, your destination for a seamless and
              innovative shopping experience. We are passionate about bringing
              you the best products and making your shopping journey not just
              convenient but enjoyable.{" "}
            </p>

            <p className="footer-text mail">helpcartshopping@gmail.com</p>
            <p className="footer-text mail">+91-8017491217</p>
          </div>

          <div className="footer-right">
            <div className="footer-column">
              <h6>Links</h6>
              <ul>
                <li>
                  {" "}
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/About" className="nav-link">
                    About{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/Products" className="nav-link">
                    Products
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <h6>Connect</h6>
              <ul>
                <li>
                  <Link to="/Contact" className="nav-link">
                    Contact{" "}
                  </Link>
                </li>
                <li>
                  <a href="mailto:cartshoppinghelp@gmail.com"  className="nav-link">Mail Us</a>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448193.95105644997!2d76.76355860593645!3d28.64428734737207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1697603813753!5m2!1sen!2sin" width="300" height="200"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map" className="map"/>
            </div>
          </div>
        </footer>
        <div className="footer-center">
          <p className="footer-rights">All rights reserved by @CartShop</p>
        </div>
      </div>
    </>
  );
};

export default Footer;

import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from '../Home/Home'
import About from '../Aboutus/AboutUs'
import Products from '../Products/Product';

import ProductDetails from '../Products/ProductDetail';
import BuyForm from '../UserInfromation/BuyForm';
import Contact from '../Contact/Contact';


const Pagerouting = () => {
  return (
    <>
     <Router>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route  path="/About" element={<About/>} />
          <Route  path="/Contact" element={<Contact/>} />
          <Route  path="/Products" element={<Products/>} />
          <Route path="/product/:productId" element={<ProductDetails />} />
          <Route path="/product/:productId/buy" element={<BuyForm />} />

        </Routes>
   </Router>
    </>
  )
}

export default Pagerouting

// Toast.js
import React, { useState, useEffect } from 'react';

const Toast = ({ message, onClose }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      onClose();
    }, 5000); 

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  return show ? (
    <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
      <div className="toast-body">
        {message}
      </div>
    </div>
  ) : null;
};

export default Toast;

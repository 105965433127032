import React from "react";
import { useParams } from "react-router-dom";
import { productData } from "../../Products/Product";
import { useNavigate } from "react-router-dom";
import "./Products.css";
import {
  Link
} from "react-router-dom";

const ProductDetail = () => {
  const navigate = useNavigate();
  const { productId } = useParams();

  // Find the product with the matching ID
  const product = productData.find((p) => p.id === parseInt(productId, 10));

  return (
    <div>
  <Link to='/' className="Homelink">HOME</Link>
  <br/><br/>
      {product ? (
        <div>
          <div class="detailscontainer">
            <div class="box">
              <img
                src={product.image}
                alt={product.title}
                className="productimg" 
              />
            </div>
            <div class="box">
              <h2 className="title">{product.title}</h2>
              <p className="desc">{product.description}</p>
              <p className="desc">{product.more}</p>
              <p>
                Shipping: <br />
                Contact supplier to negotiate shipping details Enjoy On-time
                Dispatch Guarantee Enjoy Fast QR and secure payments Returns
                &amp; Refunds Eligible for refunds within 30 days of receiving
                products.
                <br />2 Years Warranty
              </p>
              <p className="oprice">Original Price: ₹{product.oprice}</p>
              <p className="price">Our Price: ₹{product.price}</p>
              <button
                className="btn btn-primary"
                onClick={() => {
                  navigate(`/product/${productId}/buy`, {
                    state: {
                      productTitle: product.title,
                      productId: productId,
                      productPrice: product.price
                    },
                  });
                }}
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      ) : (
        <p>Product not found.</p>
      )}
    
    </div>
  );
};

export default ProductDetail;

import React from "react";
import Cards from "../../Components/JSX/Cards";
// import Hero from "../../Components/JSX/Herosection";
import Banner from "../../Components/JSX/Banner";
import Footer from "../../Components/JSX/Footer";
import Navbar from "../../Components/JSX/Navbar";
import './Home.css'
import Bannerimg from '../../Assets/Images/Banner1.png'

const Home = () => {
  return (
    <>
      {/* <Hero/> */}
      
      <Navbar className="navbox"/>
      <Banner/>
      <figure className="text-center mt-4 mb-1">
        <blockquote className="blockquote">
          <h1>- OUR PRODUCTS -</h1>
        </blockquote>
      </figure>
      <br/><br/>
      <Cards className="cards" />
      <br/><br/>
      <img src={Bannerimg} alt="Banner" className="bannerimg"/>
      <Footer/>

    </>
  );
};

export default Home;

// Banner.js
import React from 'react';
import Banner1 from '../../Assets/Images/Banner1.png'
import Banner2 from '../../Assets/Images/Banner2.png'
import Banner3 from '../../Assets/Images/Banner3.png'
import Banner4 from '../../Assets/Images/Banner4.png'

const Banner = () => {
  return (
    <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
      
      <div className="carousel-inner">
      <div className="carousel-item active">
          <img src={Banner1} alt="Image1" className="d-block w-100" />
        </div>
        <div className="carousel-item active">
          <img src={Banner2} alt="Image2" className="d-block w-100" />
        </div>
        <div className="carousel-item">
          <img src={Banner3} alt="Image3" className="d-block w-100" />
        </div>
        <div className="carousel-item">
          <img src={Banner4} alt="Image4" className="d-block w-100" />
        </div>
      </div>
      <a className="carousel-control-prev" href="#carouselExample" role="button" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </a>
      <a className="carousel-control-next" href="#carouselExample" role="button" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </a>
    </div>
  );
}

export default Banner;

import './App.css';
import Main from './Pages/Routing/Pagerouting'


function App() {
  return (
  <>
  <Main/>
  </>
  );
}

export default App;

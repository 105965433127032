import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toast from '../../Components/JSX/Toast';
import axios from 'axios';
import '../CSS/ContactForm.css'



const ContcatForm = () => {
 
  const navigate = useNavigate();

  const [showToast, setShowToast] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    
  });


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit =  async (e) => {
    e.preventDefault();
    

    const formData = {
        name: e.target.name.value,
        email: e.target.email.value,
        message: e.target.message.value,
      };

      try {
        // Send a POST request to SheetDB API
        const response = await axios.post(
          'https://sheetdb.io/api/v1/l982vs5u9vi8h', 
          formData
        );
  
        if (response.status === 201) {
          // Data was successfully added to the Google Sheet
          console.log('Form data saved successfully.');
        } else {
          console.error('Failed to save form data.');
        }

    } catch (error) {
        console.error('Error saving form data:', error);
      }
    

    // Show the toast-----------------------
    
    setShowToast(true);
    

    // Redirect to the home page after 1000ms (1 second)
    setTimeout(() => {
      navigate('/');
    }, 3000);
  };

  return (
    <>



<form onSubmit={handleSubmit}>
  <div className="mb-3">
    <label htmlFor="name" className="form-label">
      Name
    </label>
    <input
      type="text"
      className="form-control"
      id="name"
      name="name"
      value={formData.name}
      onChange={handleChange}
    />
  </div>
  <div className="mb-3">
    <label htmlFor="email" className="form-label">
      Email
    </label>
    <input
      type="email"
      className="form-control"
      id="email"
      name="email"
      value={formData.email}
      onChange={handleChange}
    />
  </div>
  <div className="mb-3">
    <label htmlFor="message" className="form-label">
      Message
    </label>
    <input
      type="text"
      className="form-control"
      id="message"
      name="message"
      value={formData.message}
      onChange={handleChange}
    />
  </div>
 

  <button type="submit" className="btn btn-primary">
    Submit
  </button>
</form>



      {showToast && (
        <Toast message="We will contact you soon !" onClose={() => setShowToast(false)} className='toast' />
      )}
    </>
  );
};

export default ContcatForm;

import Product1 from '../Assets/ProductImages/Product1.jpg'
import Product2 from '../Assets/ProductImages/Product2.jpg'
import Product3 from '../Assets/ProductImages/Product3.jpg'
import Product4 from '../Assets/ProductImages/Product4.jpg'
import Product5 from '../Assets/ProductImages/Product5.jpg'
import Product6 from '../Assets/ProductImages/Product6.jpg'
import Product7 from '../Assets/ProductImages/Product7.png'

export const productData =[
    {
      id: 1,
      image: Product1,
      title: "Mini Keychain Powerbank",
      description: "New Mini Keychain Powerbank Backup Portable Charger Key Chain Emergency Power Bank Small Power Bank.",
      oprice:1500.00,
      price: 680.00,
      more : "Key attributes : 1.Output Interface: Type C, Apple Interface, 2. Input Interface: TYPE-C, 3. Function: 1A"
    },

    {
      id: 2,
      image: Product2,
      title: "Mini Keychain Solar Powerbank",
      description: "Hot Selling Small Portable Power key - Solar Power Bank Keychain fro all smart phone",
      oprice:1800.00,
      price: 799.00,
      more : "Key attributes : 1.Output Interface: Type C, Apple Interface, 2. Input Interface: TYPE-C, 3. Socket Standard: other"
    },

    {
      id: 3,
      image: Product3,
      title: "BOPAI luggage",
      description: "BOPAI custom logo 24 inches large travel luggage durable made stainless stell ABS rod alumunium frame luxry suitcase for 20 inch",
      oprice:1800.00,
      price: 1150.00,
      more : "Key attributes : 1.Place of origin: Mumbai, India, 2. With Lock: Yes, 3. Have Drawbars: Yes"
    },

    {
      id: 4,
      image: Product4,
      title: "Charging Heating mobile/cup",
      description: "Wholesale technology gadgets electronic innovative 2023 smart desktop heating colling cup with smart phone charging",
      oprice:1600.00,
      price: 799.00,
      more : "Key attributes : 1.Place of origin: Mumbai, India, 2. Brand: Paltier , 3. Model: PT-502"
    },

    {
      id: 5,
      image: Product5,
      title: "Cordless rechargeable vacuum",
      description: "300w handheld rechageable cordless vacuum cleaner hand cyclone vacuum cleaner stick prices",
      oprice:1800.00,
      price: 1400.00,
      more : "After-sales service provided : Free spare parts, warranty : 1 year, type: cyclone"
    },

    {
      id: 6,
      image: Product6,
      title: "HOUZAIDE Electric Pulse Neck Massager for Pain Relief, Wireless Cervical pain relief Massager with Heat, Rechargeable Cordless Deep Tissue massager for",
      description: "6 Modes , 15 Levels Of Intensity And 15 Min Smart Timer: This Smart Neck Massager Is Equipped With 6 Modes,15 Levels Of Intensity,107.6F Constant-Temperature & Warm Compress Technique With An Optional Heating Function Turn On/Off As Per Need. By Emitting Low Frequency Pulses, This Neck Massager Simulates 6 Real Massage Techniques Automatic Pulse/ Scraping/ Cupping/ Shiatsu/ Knocking/ Acupuncture And 15 Adjustable Intensity Levels To Stimulate The Muscles And Meet Your Different Needs. ENJOY A RELAXING MASSAGE: Our neck massager uses the most recent technology to mimic the effectiveness of traditional massages and provide you quick relief for neck pain. Neck support for pain relief plays a big role in our wellbeing and productivity. HOUZAIDE smart neck massager is design to provide deep tissue massage with heat! It's time to relieve stiff neck pain, relax and refuel your energy. This smart massage brings full benefits of a massage parlor with none of the hassle! Warranty And Support: This Smart Neck Massager Comes With 1 Year Of Warranty From The Date Of Purchase. In Case Of Any Issue Just Call Our Customer Care And We Will Take Care Of The Your Request. Please Note This Massager Has Heat And Ems Pulse, There Is No Vibration Function.",
      oprice:1600.00,
      price: 1150.00,
      more : "Use for	Neck Power Source	Battery Powered Material	Silicone, Metal Item Weight	340 Grams Brand	HOUZAIDE WORLD OF WELLNESS & CARE Colour	White Special Feature	Heat, Pulse, Rechargeable Massager form	wrap_around_neck_massager Specific Uses For Product	Neck Pain, Cervical Pain Product Dimensions"
    },

    {
      id: 7,
      image: Product7,
      title: "Desidiya 12 Stars lifhts COMBO",
      description: "⭐ 8 Lighting Modes : This curtain light is one button control, press the button you can select 8 different modes to meet your diverse needs ⭐ Easy to use : 12 Stars(2 sizes), total 138pcs LED curtain string lights, Low voltage and ul Listed power supply, directly plug in and unplug for power on and off ⭐ Widely Used : The string lights are IP44 Waterproof, allow you to use them both indoor and outdoor. However the power and controller are non-waterproof, please protect them well when using outdoor. The lights sets are specially designed for different kinds of occasions, such as Christmas, Valentine's Day, party, wedding, home, festival, holiday, restaurant, hotel, commercial building ⭐ High Quality : The star curtain lights are made of 100% Copper Wire Material and never overheat no matter how long you have them on",
      oprice:2500.00,
      price: 1199.00,
      more : "Colour	AA Star Brand	Desidiya Indoor/Outdoor Usage	Indoor Special Feature	Heat Resistant Light Source Type	LED Power Source	Corded Electric Light Colour	Warm White Cable Length	2.5 Metres Theme	Christmas,Stars,Wedding Occasion	Christmas, Wedding, Holiday, festival, restaurants, Home, Hotel, Valentine's Day"
    },
    
  ]
  
import React, { useState, useEffect} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Toast from '../../Components/JSX/Toast';
import axios from 'axios';
import './BuyForm.css'
import QR from '../../Assets/Images/QR.png'
import CopyToClipboard from '../../Components/JSX/CopyToClipboard';



const BuyForm = () => {


  const location = useLocation();
  const { productTitle, productId, productPrice } = location.state;
  const navigate = useNavigate();

  const [showToast, setShowToast] = useState(false);

  const [formData, setFormData] = useState({
    productid:'',
    productname:'',
    productprice:'',
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    nearby: '',
    pincode: '',
  });


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit =  async (e) => {
    e.preventDefault();
    

    const formData = {
        // Add form data here, e.g., name, email, phone, address, pincode
        productid: productId,
        productname: productTitle,
        productprice: productPrice,
        name: e.target.name.value,
        email: e.target.email.value,
        phone: e.target.phone.value,
        address: e.target.address.value,
        city: e.target.city.value,
        state: e.target.state.value,
        nearby: e.target.nearby.value,
        pincode: e.target.pincode.value,
      };

      try {
        // Send a POST request to SheetDB API
        const response = await axios.post(
          'https://sheetdb.io/api/v1/3ecbya9zq8zga', // Replace with your SheetDB API URL
          formData
        );
  
        if (response.status === 201) {
          // Data was successfully added to the Google Sheet
          console.log('Form data saved successfully.');
          alert("Form data saved successfully.")
        } else {
          console.error('Failed to save form data.');
        }

    } catch (error) {
        console.error('Error saving form data:', error);
      }
    

    // Show the toast-----------------------
    
    setShowToast(true);
    

    // Redirect to the home page after 1000ms (1 second)
    setTimeout(() => {
      navigate('/');
    }, 5000);
  };

  return (
    <div className='formpage'>
      <Link to='/' className="Homelink">HOME</Link>
  <br/><br/>

<div className='formbox'>
<form onSubmit={handleSubmit}>

<div className="mb-3">
    <label htmlFor="productId" className="form-label">
      Product ID
    </label>
    <input
      type="text"
      className="form-control"
      id="productId"
      value={productId}
      disabled
    />
  </div>

<div className="mb-3">
    <label htmlFor="productTitle" className="form-label">
      Product Title
    </label>
    <input
      type="text"
      className="form-control"
      id="productTitle"
      value={productTitle}
      disabled
    />
  </div>

  <div className="mb-3">
    <label htmlFor="productPrice" className="form-label">
      Product Price
    </label>
    <input
      type="text"
      className="form-control"
      id="productPrice"
      value={"₹"+" "+productPrice}
      disabled
    />
  </div>

  <div className="mb-3">
    <label htmlFor="name" className="form-label">
      Name
    </label>
    <input
      type="text"
      className="form-control"
      id="name"
      name="name"
      value={formData.name}
      onChange={handleChange}
    />
  </div>
  <div className="mb-3">
    <label htmlFor="email" className="form-label">
      Email
    </label>
    <input
      type="email"
      className="form-control"
      id="email"
      name="email"
      value={formData.email}
      onChange={handleChange}
    />
  </div>
  <div className="mb-3">
    <label htmlFor="phone" className="form-label">
      Phone Number
    </label>
    <input
      type="tel"
      className="form-control"
      id="phone"
      name="phone"
      value={formData.phone}
      onChange={handleChange}
    />
  </div>
  <div className="mb-3">
    <label htmlFor="address" className="form-label">
      Address
    </label>
    <input
      type="text"
      className="form-control"
      id="address"
      name="address"
      value={formData.address}
      onChange={handleChange}
    />
  </div>
  <div className="mb-3">
    <label htmlFor="city" className="form-label">
      City
    </label>
    <input
      type="text"
      className="form-control"
      id="city"
      name="city"
      value={formData.city}
      onChange={handleChange}
    />
  </div>
  <div className="mb-3">
    <label htmlFor="state" className="form-label">
      State
    </label>
    <input
      type="text"
      className="form-control"
      id="state"
      name="state"
      value={formData.state}
      onChange={handleChange}
    />
  </div>
  <div className="mb-3">
    <label htmlFor="nearby" className="form-label">
      Nearby
    </label>
    <input
      type="text"
      className="form-control"
      id="nearby"
      name="nearby"
      value={formData.nearby}
      onChange={handleChange}
    />
  </div>
  <div className="mb-3">
    <label htmlFor="pincode" className="form-label">
      Pincode
    </label>
    <input
      type="text"
      className="form-control"
      id="pincode"
      name="pincode"
      value={formData.pincode}
      onChange={handleChange}
    />
  </div>

  <div className="mb-3">
    <label htmlFor="pincode" className="form-label">
      SCAN QR CODE AND PAY
    </label>
    <br/>
      <img src={QR} alt='QR Code' className='qrcode'/>
      <br/>
      <CopyToClipboard />
      <br/>
      

  </div>
  <div className="mb-3">
    <label htmlFor="pincode" className="form-label">
      NOTE - Scan QR code and pay, We will confirm payment and  deliver your product to your home. And press the submit button twice, click once and wait 5 seconds please.
    </label>
  </div>
  <button type="submit" className="btn btn-primary">
    Submit
  </button>
</form>


</div>

<br/>



      {showToast && (
        <Toast message="Thank you for your purchase! 🎊🎉" onClose={() => setShowToast(false)} className='toast' />
      )}
    </div>
  );
};

export default BuyForm;

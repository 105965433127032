import React from 'react'
import '../CSS/Cards.css'
import {productData} from '../../Products/Product'; 
import { Link } from 'react-router-dom';

const Cards = () => {

    
  return (
    <>

<div className='cardbox'>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {productData.map((product) => (
          <div className="col " key={product.id}>
            <div className="card h-100 cards">
              <img src={product.image} className="card-img-top proimg" alt={product.title} />
              <div className="card-body">
                <h5 className="card-title">{product.title}</h5>

                <span class="d-inline-block text-truncate" style={{maxWidth: "200px"}}>
                {product.description}
</span>
                
              </div>
              <div className="card-footer">
              <small className="text-body-secondary oprice" > ₹{product.oprice}</small> &nbsp;
                <small className="text-body-secondary">Price: ₹{product.price}</small>
                
                <Link to={`/product/${product.id}`} className="btn btn-primary buy-now-button">
                Buy Now
              </Link>


              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

    </>
  )
}

export default Cards

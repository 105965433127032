import React from 'react'
import Cards from '../../Components/JSX/Cards'
import Footer from '../../Components/JSX/Footer'
import './Products.css'
import "./Products.css";
import {
  Link
} from "react-router-dom";

const Product = () => {
  return (
    <div className='allproducts'>
      <Link to='/' className="Homelink">HOME</Link>
  <br/><br/>
    <figure className="text-center mt-5 mb-4">
        <blockquote className="blockquote">
          <h1>OUR PRODUCTS</h1>
        </blockquote>
      </figure>
      <Cards />
      <Footer/>
    </div>
  )
}

export default Product

import React from 'react'
import './Contact.css'
import Footer from '../../Components/JSX/Footer'
import ContactForm from '../../Components/JSX/ContactForm'
import contact from '../../Assets/Images/contact.jpg'
import {
  Link
} from "react-router-dom";

const Contact = () => {
  return (
    <>
    
    <div className='ContactPage'>
    <Link to='/' className="Homelink">HOME</Link>
        <div className='contactform'>
            <div className='cimg'>
<img src={contact} alt="contact" className='cimg' />
            </div>
            <br/><br/>
            <div className='cform'>
          <ContactForm/>
            </div>
        </div>
    
    
    </div>
    <Footer/>
    </>
  )
}

export default Contact

import React, { useEffect } from 'react';
import ClipboardJS from 'clipboard';
import Toast from './Toast';

const CopyToClipboard = () => {
  const [showToast, setShowToast] = React.useState(false);

  useEffect(() => {
    const clipboard = new ClipboardJS('.copy-btn');

    clipboard.on('success', function (e) {
      // Show the toast when text is copied
      setShowToast(true);
      e.clearSelection();
    });
  }, []);

  const closeToast = () => {
    setShowToast(false);
  };

  return (
    <div>
      <h5 className='upiid'>
        <span
          className="copy-btn"
          data-clipboard-text="dadsltd@axl"
          style={{ cursor: 'pointer' }}
        >
          UPI ID - dadsltd@axl
        </span>
      </h5>

      {showToast && (
        <Toast message="Your UPI ID is copied" onClose={closeToast} />
      )}
    </div>
  );
};

export default CopyToClipboard;

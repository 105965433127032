import React, { useState } from 'react';
import '../CSS/Navbar.css'
import Logo from '../../Assets/Images/Logo.png'
import {
  Link
} from "react-router-dom";


const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light navbarbox">
      <div className="container">
        {/* Logo */}
        <a className="navbar-brand" href="/" style={{zIndex: showMenu ? -1 : 'auto' }}>
          <img
            src={Logo} // Replace with your logo image URL
            alt="Logo"
            className='logo'
          />
          <a className="navbar-brand" href="/">
          CARTSHOP
        </a>
        </a>

        {/* Hamburger Button */}
        <button
          className="navbar-toggler tbtn"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded={showMenu ? 'true' : 'false'}
          aria-label="Toggle navigation"
          onClick={toggleMenu}
        >
          {showMenu ? (
            <i class="bi bi-x-circle-fill text-danger"></i> // "X" icon for close
          ) : (
            <i class="bi bi-list "></i> // Hamburger icon
          )}
        </button>

        {/* Responsive Menu */}
        <div className={`collapse navbar-collapse ${showMenu ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto" >
            <li className="nav-item  navi-links">
            <Link to='/' className=" navi-links">Home</Link>
            </li>
            <li className="nav-item  navi-links">
            <Link to='/Products' className=" navi-links">Products</Link>
            </li>
            <li className="nav-item  navi-links ">
            <Link to='/About' className=" navi-links">About</Link>
            </li>
            {/* <li className="nav-item nav-links">
            <Link to='/About' className="nav-link">About</Link>
            </li> */}
          </ul>

          {/* "Try for Free" Button */}
          <div className="ml-auto">
          <Link to='/Contact' className="nav-link navi-links"><button className="btn btn-light" style={{ marginRight: '20px' }}>
              Contact Us
            </button></Link>
            
          </div>
        </div>
      </div>
    </nav>
    </>
  )
}

export default Navbar
